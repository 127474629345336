import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  constructor(private toastr: ToastrService) {}

  /**
   * Shows a success toast message.
   * @param message The message to display.
   * @param title The title of the toast (optional).
   */
  success(message:string = 'Success', title?: string) {
    this.toastr.success(message, title);
  }

  /**
   * Shows a success toast message. If no title is provided, it defaults to the message.
   *
   * @param message - The message to display.
   * @param title - The title of the toast (optional).
   *
   */
  show(message: string = 'Show', title?: string) {
    this.toastr.success(message, title);
  }

  /**
   * Shows an error toast message.
   * @param message The message to display.
   * @param title The title of the toast (optional).
   */
  error(message: string = 'Error', title?: string) {
    this.toastr.error(message, title);
  }

  /**
   * Shows an info toast message.
   * @param message The message to display.
   * @param title The title of the toast (optional).
   */
  info(message: string = 'Info', title?: string) {
    this.toastr.info(message, title);
  }

  /**
   * Shows a warning toast message.
   * @param message The message to display.
   * @param title The title of the toast (optional).
   */
  warn(message: string = 'Warn', title?: string) {
    this.toastr.warning(message, title);
  }
}