import { ModuleWithProviders, NgModule, Provider } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OverlayPanelModule } from '../shared/modules/overlay-panel/overlay-panel.module';
import { NotificationsComponent } from './components/notifications/notifications.component';
import { FormsModule } from '@angular/forms';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { DateAgoPipe } from './pipes/date-ago/date-ago.pipe';
import { RouterModule } from "@angular/router";
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireMessagingModule, SERVICE_WORKER, VAPID_KEY } from '@angular/fire/compat/messaging';
import {environment} from "src/environments/environment";
import { NotificationService } from './services/notifications/notification.service';
import { INotificationConfig } from './interfaces/notification-config.interface';
import { NotificationAction } from './tokens/notification-actions.token';
import { NotificationActionsService } from './services/notifications/notification-actions.service';
import { ToastModule } from '../tools/toast/toast.module';

@NgModule({
  declarations: [
    NotificationsComponent,
    DateAgoPipe,
  ],
  imports: [
    CommonModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireMessagingModule,
    OverlayPanelModule,
    FormsModule,
    InfiniteScrollModule,
    RouterModule,
    ToastModule
  ],
  exports: [NotificationsComponent, AngularFireMessagingModule],
})
export class NotificationModule {

  static forRoot(config: INotificationConfig = {}): ModuleWithProviders<NotificationModule> {
    return {
      ngModule: NotificationModule,
      providers: [
        NotificationService,
        config?.notificationAction ?? {provide: NotificationAction, useExisting: NotificationActionsService},
        {
          provide: VAPID_KEY,
          useValue: environment.VAPIDKEY
        },
        { provide: SERVICE_WORKER, useFactory: () => typeof navigator !== 'undefined' && navigator.serviceWorker?.register('firebase-messaging-sw.js', { scope: '__' }) || undefined },
      ]
    }
  }
}
