import { inject, LOCALE_ID, Pipe, PipeTransform } from '@angular/core';
import { TranslationService } from 'src/app/kernel/translations/translation.service';
import { ELocalizationLang } from 'src/app/vogo-core/enums';

@Pipe({
  name: 'dateAgo',
})
export class DateAgoPipe implements PipeTransform {
  private translationService = inject(TranslationService);
  private lang = inject(LOCALE_ID);

  transform(value: Date | undefined): any {
    if (!value) return '';
    const seconds = Math.floor((+new Date() - +new Date(value)) / 1000);
    const SECONDS_IN_MINUTE = 60;
    const SECONDS_IN_HOUR = 3600;
    const SECONDS_IN_DAY = 86400;
    const SECONDS_IN_WEEK = 604800;

    if (seconds < SECONDS_IN_MINUTE) {
      return this.translateTime('now');
    }

    if (seconds >= SECONDS_IN_MINUTE && seconds < SECONDS_IN_HOUR) {
      // return `${Math.floor(seconds / SECONDS_IN_MINUTE)} minutes ago`;
      return `${this.translateTime('notifications.minutes_ago',Math.floor(seconds / SECONDS_IN_MINUTE))}`;
    }

    if (seconds >= SECONDS_IN_HOUR && seconds < SECONDS_IN_DAY) {
      // return `${Math.floor(seconds / SECONDS_IN_HOUR)} hours ago`;
      return `${this.translateTime('notifications.hours_ago', Math.floor(seconds / SECONDS_IN_HOUR))}`;
    }

    if (seconds >= SECONDS_IN_DAY && seconds < SECONDS_IN_WEEK) {
      return `${this.translateTime('notifications.days_ago', Math.floor(seconds / SECONDS_IN_DAY))}`;
    }

    return `${this.translateTime('notifications.weeks_ago', Math.floor(seconds / SECONDS_IN_WEEK))}`;
  }

  private translateTime(key: string, time?: number) {
    if(this.lang === ELocalizationLang.EN) {
      return `${time ?? ''} ${this.translationService.instant(key)}`
    } else {
      return key === 'now' ? `${this.translationService.instant('notifications.now')}`
        : `${this.translationService.instant('notifications.ago')} ${time} ${this.translationService.instant(key)}`
    }
  }
}
